<template>
	<div class="product-price overflow-hidden">
		<span v-if="displaySaleAmount" class="sale-amount">{{ formattedSaleAmount }}</span
		><span v-if="displayAmount" class="amount">{{ formattedAmount }}</span
		><span v-if="displayInterval" class="price-interval">/{{ formattedInterval }}</span>
	</div>
</template>
<script>
	import { formatCurrency } from './utils'
	export default {
		props: {
			currency: { type: String, default: 'USD' },
			saleAmount: { type: Number, default: undefined },
			amount: { type: Number, default: undefined },
			interval: { type: String, default: undefined },
		},
		computed: {
			displayAmount() {
				return this.amount && this.amount > 0
			},
			displaySaleAmount() {
				return this.saleAmount && this.saleAmount > 0
			},
			displayInterval() {
				return this.amount && this.interval
			},
			formattedAmount() {
				return formatCurrency(parseFloat(this.amount), this.currency)
			},
			formattedSaleAmount() {
				return formatCurrency(parseFloat(this.saleAmount), this.currency)
			},
			formattedInterval() {
				if (this.interval) {
					return this.interval.charAt(0).toUpperCase() + this.interval.slice(1)
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.product-price {
		span:first-child {
			color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
			margin-right: 5px;
		}

		span:nth-child(2) {
			color: #cad1e1;
			text-decoration: line-through;
			margin-right: 5px;
		}

		span.price-interval {
			color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
			text-decoration-line: none !important;
		}
	}
</style>

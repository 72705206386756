<template>
	<div class="pages-block px-5-mod relative text-center mt-5 mb-5" key="one-time">
		<div class="upsell">
			<strong class="text-danger text-decoration-underline text-bold d-block">{{ $t('shared.order_bump.one_time_offer') }}</strong>
			<small class="d-block mb-1">
				<span
					v-if="isOnSale"
					v-html="$t('shared.order_bump.offer_sale', { title: secondBuiltInProduct.data.product.title, price, originalPriceStrike })"
				/>
				<span v-else v-html="$t('shared.order_bump.offer', { title: secondBuiltInProduct.data.product.title, price })" />
			</small>
			<div class="button" :style="{ background: userPrimaryColor }">
				<i class="v-icon blink" style="color: red; background-color: transparent">
					<svg width="24" height="24" viewBox="0 0 24 24">
						<path d="M4 15V9h8V4.16L19.84 12L12 19.84V15H4z">
							<animate attributeType="XML" attributeName="fill" values="#800;#f00;#800;#800" dur="0.8s" repeatCount="indefinite" />
						</path>
					</svg>
				</i>
				<input id="upsell_check" v-model="upsellFlag" :disabled="isPageProcessing" type="checkbox" />
				<label for="upsell_check">{{ $t('shared.order_bump.yes') }}</label>
			</div>
		</div>
	</div>
</template>

<script>
	import Il8nMixin from './Il8nMixin'
	export default {
		mixins: [Il8nMixin],
		props: {
			secondBuiltInProduct: { type: Object },
			user: { type: Object },
			userPrimaryColor: { type: String },
			add_upsell_to_order: { type: Boolean },
			isPageProcessing: { type: Boolean },
		},
		computed: {
			isOnSale() {
				const price = this.secondBuiltInProduct.data.product.price
				return price.sale_amount_available
			},
			originalPriceStrike() {
				const user = this.user
				const price = this.secondBuiltInProduct.data.product.price
				const origPrice = this.formatCurrency(price.amount, user.data.currency)
				return `<span style="text-decoration: line-through">${origPrice}</span>`
			},
			price() {
				const user = this.user
				const price = this.secondBuiltInProduct.data.product.price
				const origPrice = this.formatCurrency(price.amount, user.data.currency)
				if (this.isOnSale) {
					const salePrice = this.formatCurrency(price.sale_amount, user.data.currency)
					return salePrice
				}
				return origPrice
			},
		},
		data() {
			return {
				upsellFlag: false,
			}
		},
		methods: {
			formatCurrency(amount, currency = 'USD', locale = 'en-US', longSymbolUs = false) {
				const minimumFractionDigits = (amount * 10) % 10 > 0 ? 2 : 0
				return (
					(longSymbolUs && currency === 'USD' ? 'US' : '') +
					Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits, maximumFractionDigits: 2 }).format(amount)
				)
			},
		},
		watch: {
			upsellFlag(newVal) {
				this.$emit('input', newVal)
			},
		},
	}
</script>

export * from './bookings'
export * from './payments'

export const PAGE_TYPES = {
	DIFITAL_DOWNLOAD: 'digital-download',
	MEETING: 'meeting',
	LEAD_MAGNET: 'lead-magnet',
	THANK_YOU: 'thank-you',
	FULFILLMENT: 'fulfillment',
	MEMBERSHIP: 'membership',
	LINK: 'link',
	STAN_REFERRAL_LINK: 'stan-referral-link',
	COURSE: 'course',
	WEBINAR: 'webinar',
}

export const PAGE_ERROR_CODES = {
	INVALID_FORM: 'G00',
	FAN_NAME: 'G04',
	FAN_EMAIL: 'G01',
	FORM_SUBMITTING: 'G02',
	NO_STOCK: 'G03',
	ACCEPT_TC: 'TC01',
	UNAPPLIED_DC: 'DC01',
	BOOKING_TIME: 'M01',
	BOOKING_DATE: 'M02',
	BOOKING_START_TIME: 'M03',
	BOOKING_END_TIME: 'M04',
	BOOKING_TAKEN: 'M05',
	WEBINAR_MISSING_DETAILS: 'W00',
	WEBINAR_NO_TIMES_AVAILABLE: 'W01',
	WEBINAR_TIME_NOT_SELECTED: 'W02',
	PAYMENT_ERROR: 'P00',
	PAYMENT_ERROR_RECEIVED_BLOCK: 'P01',
	PAYMENT_ERROR_SUCCESS_BLOCK: 'P02',
	TAX: 'T00',
	REQUEST_GENERAL_ERROR: 'R00',
	INVALID_NAME: 'I01',
	INVALID_EMAIL: 'I02',
	INVALID_QUESTION: 'I03',
}

export const CODE_TO_ERROR_MESSAGE_TYPE = {
	G00: { type: 'error' },
	G01: { type: 'error' },
	G02: { type: 'error' },
	G03: { type: 'error' },
	G04: { type: 'error' },
	TC01: { type: 'error' },
	DC01: { type: 'warn' },
	M01: { type: 'error' },
	M02: { type: 'error' },
	M03: { type: 'error' },
	M04: { type: 'error' },
	M05: { type: 'error' },
	T00: { type: 'error' },
	W00: { type: 'error' },
	W01: { type: 'error' },
	W02: { type: 'error' },
	I01: { type: 'error' },
	I02: { type: 'error' },
	I03: { type: 'error' },
}

export const TAG_TYPES_LIST = ['aid', 'fid']

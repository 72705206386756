<template>
	<div>
		<div class="page-block mb-5 d-flex justify-content-start align-items-start terms__heading" key="submit-block">
			<input class="terms-checkbox" id="terms-checkbox" type="checkbox" v-model="isTermsAndConditionChecked" />
			<div>
				<label for="terms-checkbox">{{$t("terms_and_conditions.check_box_link")}}</label>
				<a class="cursor-pointer terms-link" @click="openTermsAndConditions">{{$t("terms_and_conditions.terms_and_conditions")}}</a>.
			</div>
		</div>
		<div class="page-block terms__content" key="submit-block-popup" v-if="showTermsAndConditions">
			<div class="description d-flex flex-wrap align-items-start">
				<div class="tac-back-button" @click="closeTermsAndConditions">
					<Icon class="icon-svg" name="close-no-border" :scale="1" />
				</div>

				<!-- Content -->
				<div style="display: block">
					<TipTapViewer
						class="pages-block px-5-mod"
						:value="termsAndConditions"
						:accent-color="userPrimaryColor"
						key="description"
					></TipTapViewer>
				</div>
				<!-- Accept button -->
				<div class="w-100">
					<button class="btn-tool" @click="acceptTermsAndConditions">{{$t('terms_and_conditions.accept')}}</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import TipTapViewer from '~/stan-vue-shared/components/TipTapViewer'
	import Icon from '~/stan-vue-shared/components/icons/Icon.vue'

	export default {
		name: 'TermsAndConditions',
		components: {
			TipTapViewer,
			Icon,
		},
		props: {
			termsAndConditions: { type: String, default: '' },
			userPrimaryColor: { type: String, default: '' },
		},
		watch: {
			isTermsAndConditionChecked(newVal) {
				this.$emit('stateChanged', newVal)
				if (newVal) {
					this.$emit('sendAnalytic', 'product-purchase-terms-agreed')
				}
			},
		},
		data() {
			return {
				showTermsAndConditions: false,
				isTermsAndConditionChecked: false,
			}
		},
		methods: {
			openTermsAndConditions() {
				this.showTermsAndConditions = true
				document.body.classList.add('terms-open')
				this.$emit('sendAnalytic', 'product-purchase-terms-read')
			},
			closeTermsAndConditions() {
				this.showTermsAndConditions = false
				document.body.classList.remove('terms-open')
			},
			acceptTermsAndConditions() {
				this.isTermsAndConditionChecked = true
				document.body.classList.remove('terms-open')
				this.showTermsAndConditions = false
			},
		},
	}
</script>
<style lang="scss">
	.terms-open {
		overflow-y: hidden;
	}
</style>
<style lang="scss" scoped>
	a {
		color: var(--stan-store-theme-primary-color);
	}
	.terms__content {
		background: #adadadad;
		font-size: 87%;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		overflow: auto;
		z-index: 101 !important; // Paypal and PayLater iframe has z-index of 100

		> .description {
			padding-bottom: 20px;

			> .tac-back-button {
				position: relative;
				padding: 10px;
				background-color: #ffffff;
				border-radius: 50%;
				min-width: 24px;

				&:hover {
					cursor: pointer;
				}

				svg {
					min-width: 24px;
					width: 24px;
				}
			}

			> .w-100 {
				display: flex;
				justify-content: center;
			}
		}
	}
	.description {
		align-items: flex-start;
		background-color: white;
		max-width: 475px;

		margin: 0 auto;
		padding-top: 1rem;
		overflow-y: scroll;

		/* IPad styling*/
		@media (min-width: 768px) {
			max-width: 800px;
		}

		/* Desktop styling */
		@media (min-width: 1344px) {
			margin-top: 30px;
			margin-bottom: 30px;
			max-height: calc(100% - 60px);
			padding: 20px;
			box-shadow: var(--stan-box-shadow);
		}
	}

	.cursor-pointer {
		cursor: pointer;
	}
	.terms-checkbox {
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		appearance: none;
		/* For iOS < 15 to remove gradient background */
		background-color: transparent;
		/* Not removed via appearance */
		margin: 2px;
		margin-right: 10px;
		font: inherit;
		color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
		width: 1.3em;
		height: 1.3em;
		aspect-ratio: 1;
		border: 0.15em solid var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
		display: grid;
		place-content: center;
		&::before {
			content: '';
			width: 1em;
			height: 1em;
			transform: scale(0);
			transition: 0.3s transform ease-in-out;
			transform-origin: bottom left;
			clip-path: polygon(99% 21%, 35% 88%, 0% 51%, 10% 41%, 35% 68%, 88% 11%, 99% 21%);
			box-shadow: inset 1em 1em var(--stan-store-theme-button-text-color, #ffffff);
			border-radius: 0.15em;
		}
		&:checked::before {
			transform: scale(1);
		}
		&:checked {
			background-color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
		}
	}
</style>

<template>
	<div class="external-links-block w-100">
		<div class="m-auto privacy-policy" @click="openPrivacyPolicy">{{ $t('shared.external_links.privacy_policy') }}</div>
	</div>
</template>
<script>
	import Il8nMixin from './Il8nMixin'
	export default {
		name: 'ExternalLinks',
		mixins: [Il8nMixin],
		methods: {
			openPrivacyPolicy() {
				const url = 'https://assets.stanwith.me/legal/privacy-policy.pdf'
				console.log('openPrivacyPolicy')
				window.open(url, '_blank')
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '~/stan-vue-shared/assets/styles/themes/index.scss';

	.external-links-block {
		color: #888888;
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 50px;
		margin-bottom: 20px;
		cursor: pointer;
		font-size: 12px;
		text-decoration: underline;
	}

	.m-auto {
		margin: auto;
	}
</style>

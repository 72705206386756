<template>
	<div>
		<div v-html="marketingHTML"></div>
	</div>
</template>
<script>
	export default {
		watch: {},
		name: 'MarketingPage',
		props: { page: { type: Object, default: () => {} } },
		mounted() {},
		computed: {
			marketingHTML() {
				return this.page?.data.product.marketing_page?.html || ''
			},
		},
	}
</script>
<style lang="scss" scoped></style>

<template>
	<div :class="{ submitting: isFormSubmitting }">
		<div id="adyen-card-container"></div>
	</div>
</template>

<script>
	import AdyenCheckout from '@adyen/adyen-web'
	import '@adyen/adyen-web/dist/adyen.css'

	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	export default {
		name: 'AdyenPayment',
		props: {
			username: {
				type: String,
				require: true,
			},
			pageId: {
				type: Number,
				require: true,
			},
			amount: {
				type: Number,
				default: 0,
			},
			currency: {
				type: String,
				require: true,
				default: 'USD',
			},
			fcpComplete: {
				type: Boolean,
				default: false,
			},
			isPaid: {
				type: Boolean,
				default: false,
			},
			triggerSubmit: {
				type: Function,
			},
			isFormSubmitting: {
				type: Boolean,
			},
			isProductRecurring: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				name: 'adyen',
				clientId: undefined,
				merchantId: undefined,
				adyenReady: false,
				paymentElement: undefined,
				orderId: undefined,
			}
		},
		computed: {
			paymentData() {
				return {
					transaction_origin: 'user',
				}
			},
		},
		methods: {
			async init() {
				if (!this.isPaid || this.isProductRecurring) {
					this.$nextTick(() => {
						this.$emit('gatewayAvailable', { name: this.name, available: false })
					})
					return
				}

				await loadCaptcha(this.$config.captcha_site_key)
				grecaptcha.enterprise.ready(() => {
					this.createPaymentElement()
				})
			},
			async createPaymentElement() {
				try {
					const payload = { username: this.username }
					const response = await this.$axios.post('v1/integrations/adyen/get-client-info', payload)
					const paymentMethodsResponse = response.data.payment_methods
					const clientKey = response.data.client_key
					const environment = response.data.environment
					const locale = Navigator.language || 'en-US'
					const configuration = {
						locale: locale, // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/online-payments/web-components/localization-components.
						environment: environment,
						clientKey: clientKey,
						analytics: {
							enabled: true, // Set to false to not send analytics data to Adyen.
						},
						paymentMethodsResponse: paymentMethodsResponse, // The payment methods response returned from the POST /paymentMethods request.
						showPayButton: false, // Set to false if you don't want to use the pre-built Pay button.
						onSubmit: this.handleOnSubmit, // Your function for handling the onSubmit event.
						onAdditionalDetails: this.handleOnAdditionalDetails, // Your function for handling the onAdditionalDetails event.
					}

					// Create an instance of AdyenCheckout using the configuration object.
					const checkout = await AdyenCheckout(configuration)
					this.paymentElement = checkout.create('card').mount('#adyen-card-container')
					this.adyenReady = true
					setTimeout(() => this.$emit('gatewayAvailable', { name: this.name, available: true }), 20)
				} catch (ex) {
					setTimeout(() => this.$emit('gatewayAvailable', { name: this.name, available: false }), 20)
				}
			},
			async processPayment(orderId) {
				this.orderId = orderId
				this.paymentElement.submit()
				this.resultErrorMessage = ''
				this.cardError = null
			},
			async createConfirmPayment(paymentMethod) {
				let token = undefined
				try {
					token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
						action: 'CreatePaymentIntent',
					})
				} catch (error) {
					this.logBrowserUpdate(this.orderId, { message: 'Could not execute captcha', error: error })
				}
				this.$emit('paymentInit')
				const processPaymentPayload = {
					order_id: this.orderId,
					amount: this.amount,
					currency: this.currency,
					processor: 'adyen',
					processor_data: {
						payment_method: paymentMethod,
					},
					captcha_token: token,
				}
				const response = this.$axios
					.post('v1/integrations/payment/create-confirm-intent', processPaymentPayload)
					.then(response => {
						if (response.data?.status === 'Authorised') {
							this.$emit('paymentSuccess', { name: this.name, data: response.data })
						} else {
							this.$emit('paymentError', { message: this.$t('store.error.payment_error') })
						}
					})
					.catch(error => {
						this.$emit('paymentError', { message: this.$t('store.error.payment_error') })
					})
			},
			handleOnSubmit(state, component) {
				if (state.isValid) {
					this.createConfirmPayment(state.data.paymentMethod)
				}
			},
			handleOnAdditionalDetails(state, component) {
				this.$emit('paymentSuccess', { name: this.name })
			},
		},
		watch: {
			async fcpComplete(isReady) {
				if (isReady) {
					this.$nextTick(async () => {
						await this.init()
					})
				}
			},
			async isPaid() {
				if (!this.adyenReady) {
					this.$nextTick(async () => {
						await this.init()
					})
				}
			},
		},
	}
</script>

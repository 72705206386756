export function isSourceAvailable(source) {
	return !(source === null || source === '' || typeof source === 'undefined')
}

export function isSourceBase64Img(source) {
	return !!(isSourceAvailable(source) && source.startsWith('data:image/png;base64'))
}

export function isSourceSvgImg(source) {
	try {
		const url = new URL(source)
		const pathname = url.pathname
		return pathname.endsWith('.svg')
	} catch (err) {
		return false
	}
}

export function isImageProcessing(source) {
	return !(!isSourceAvailable(source) || isSourceBase64Img(source) || isSourceSvgImg(source))
}

export async function checkWebp() {
	return new Promise(resolve => {
		const img = new Image()
		img.addEventListener('load', () => {
			resolve(img.height === 2)
			img.remove()
		})
		img.addEventListener('error', () => {
			img.remove()
			resolve(false)
		})
		img.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
	})
}

export function rpartition(source, searchString) {
	if (!searchString || searchString === '') return source
	const sLen = searchString.length
	const idx = source.lastIndexOf(searchString)
	if (idx === -1) return [source, searchString, '']
	const head = source.slice(0, idx)
	const sep = source.slice(idx, idx + sLen)
	const tail = source.slice(idx + sLen, -1)
	return [head, sep, tail]
}

export function getOptimizeOptions(options) {
	const optimizeImageOptions = {}

	options.original && (optimizeImageOptions.original = options.original)
	options.quality && (optimizeImageOptions.quality = options.quality)
	options.format && (optimizeImageOptions.format = options.format)
	options.width && options.width !== -1 && (optimizeImageOptions.width = options.width)
	options.height && options.height !== -1 && (optimizeImageOptions.height = options.height)

	return optimizeImageOptions
}

export async function checkImage(src) {
	if (!isSourceAvailable(src) || isSourceBase64Img(src) || isSourceSvgImg(src)) {
		return false
	}
	try {
		const resp = await fetch(src, { method: 'HEAD', mode: 'cors' })
		return resp.ok
	} catch {
		return false
	}
}

// Note this skips webp support checks
export function getOptimizeImageUrlSync(src, options = {}, webpSupport) {
	if (!src || src === '') return src
	if (src !== '' && !src.startsWith('http')) return src
	if (options.original === 1) return src
	const width = options.width || 0
	const height = options.height || 0
	if (width === 0 && height === 0) return src

	const url = new URL(src)
	const pathname = url.pathname

	const dirname = pathname
		.split('\\')
		.pop()
		.split('/')
		.slice(0, -1)
		.join('/')
	const filename = pathname
		.split('\\')
		.pop()
		.split('/')
		.pop()

	let name = filename
		.split('.')
		.slice(0, -1)
		.join('.')

	let extension = filename.split('.').pop()
	const inFormat = options.format || 'webp'
	let format = inFormat.toLowerCase() === 'jpeg' ? 'jpg' : inFormat.toLowerCase()
	if ((extension === 'webp' || format === 'webp') && !webpSupport) {
		format = 'jpg'
	}

	const uri = []
	uri.push(dirname)

	if (name === '' && extension === filename) {
		name = filename
		extension = format
		uri.push(`${name}`)
	} else {
		uri.push(`${name}.${extension}`)
	}

	uri.push(format)
	uri.push(`${width}x${height}`)
	let quality = options.quality || 100
	quality = quality < 100 ? quality : 100

	uri.push(quality)
	uri.push(`${name}.${format}`)

	url.pathname = uri.join('/')
	return url.toString()
	
}

export async function getOptimizeImageUrl(src, options = {}) {
	if (!src || src === '') return src
	if (src !== '' && !src.startsWith('http')) return src
	if (options.original === 1) return src
	const width = options.width || 0
	const height = options.height || 0
	if (width === 0 && height === 0) return src
	const webpSupport = await checkWebp()
	
	return getOptimizeImageUrlSync(src, options, webpSupport)
}

export function preloadImage(url) {
	return new Promise((resolve, reject) => {
		const preImg = document.createElement('link')
		preImg.href = url
		preImg.rel = 'preload'
		preImg.as = 'image'
		document.head.appendChild(preImg)
		const timeout = setTimeout(() => {
			preImg.src = ''
			preImg.onload = null
			preImg.onerror = null
			reject()
		}, 10000)

		preImg.addEventListener('load', () => {
			clearTimeout(timeout)
			resolve()
		})
		preImg.addEventListener('error', () => {
			clearTimeout(timeout)
			reject()
		})
	})
}

export function getPreviewImage(src, img) {
	return new Promise((resolve, reject) => {
		fetch(src)
			.then(response => response.blob())
			.then(blob => {
				const imgReader = new FileReader()
				// imgReader.onloadend = function(ev)
				// const data = URL.createObjectURL(blob)
				imgReader.onload = function(ev) {
					img.addEventListener('load', () => {
						resolve(ev.target.result)
					})
					img.addEventListener('error', () => {
						reject()
					})
					// img.src = imgReader.result
					img.src = ev.target.result
				}
				imgReader.readAsDataURL(blob)
			})
			.catch(err => {
				reject()
			})
	})
}

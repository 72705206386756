<template>
	<div class="store-header">
		<div class="store-header__content">
			<div class="store-header__image">
				<image-preview :source="user_image" :width="433" staticClass="" alt="user image" />
			</div>
			<div class="store-header__profile">
				<div class="store-header__name-bio">
					<div class="store-header__names">
						<div class="store-header__fullname">{{ full_name }}</div>
					</div>
					<div v-if="userBio" class="store-header__bio">{{ userBio }}</div>
				</div>
				<SocialIcons :user-social-icons="socialIcons" />
			</div>
		</div>
	</div>
</template>
<script>
	import SocialIcons from './SocialIcons'
	import ImagePreview from './ImagePreview'

	export default {
		name: 'StoreHeader',
		components: {
			SocialIcons,
			ImagePreview,
		},
		props: {
			full_name: { type: String },
			username: { type: String },
			userBio: { type: String },
			user_image: { type: String },
			storeTheme: { type: String, default: 'default' },
			socialIcons: {
				type: Object,
				default() {
					return {}
				},
			},
		},
	}
</script>
<style lang="scss" scoped></style>

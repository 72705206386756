<template>
	<div id="video-wrapper" class="video-wrapper" @click="startPlaying">
		<div id="artplayer-app" class="artplayer-app art-auto-size">
			<div class="art-video-player art-subtitle-show art-layer-show art-control-show art-mask-show">
				<video
					:id="videoId"
					preload="metadata"
					class="art-video"
					:controls="isPlayStarted"
					controlslist="nodownload"
					@play="onPlay"
					@pause="onPause"
					v-if="isSupportedType"
				></video>
				<div v-else class="video-issue">
					<p class="header">{{ $t('shared.video.video_playback_error_title') }}</p>
					<p class="subheader">{{ $t('shared.video.video_playback_error_text') }}</p>
				</div>
				<div id="video-poster-src" v-show="!isPlayStarted && showMask" class="art-poster" :style="videoPosterStyle"></div>
				<div id="video-mask-src" v-show="isVideoReady && !isPlayStarted && isSupportedType" class="art-mask">
					<div class="art-state">
						<svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M27.2107 18.9707L2.47975 35.4581C2.30406 35.575 2.09995 35.6421 1.88916 35.6521C1.67837 35.6622 1.46879 35.6149 1.28275 35.5153C1.0967 35.4157 0.941156 35.2675 0.83268 35.0865C0.724203 34.9055 0.666857 34.6984 0.666748 34.4874V1.51274C0.666857 1.30171 0.724203 1.09466 0.83268 0.913645C0.941156 0.732628 1.0967 0.584423 1.28275 0.484816C1.46879 0.385209 1.67837 0.337929 1.88916 0.348013C2.09995 0.358096 2.30406 0.425166 2.47975 0.542078L27.2107 17.0294C27.3705 17.136 27.5015 17.2803 27.5922 17.4496C27.6828 17.619 27.7302 17.808 27.7302 18.0001C27.7302 18.1921 27.6828 18.3812 27.5922 18.5505C27.5015 18.7199 27.3705 18.8642 27.2107 18.9707Z"
								fill="white"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isImageProcessing,
		preloadImage,
		getOptimizeImageUrl,
		isSourceAvailable,
		isSourceBase64Img,
		isSourceSvgImg,
		checkImage,
	} from './OptimizationImageTools'
	import { emptyImage } from './utils'
	import Il8nMixin from './Il8nMixin'
	import Hls from 'hls.js'

	export default {
		name: 'VideoCmp',
		mixins: [Il8nMixin],
		props: {
			videoId: {
				type: String,
				default() {
					return 'video-src'
				},
			},
			videoFormat: {
				type: String,
				default() {
					return 'desktop'
				},
			},
			videoWidth: {
				type: Number,
				default() {
					return 600
				},
			},
			videoHeight: {
				type: Number,
				default() {
					return 337
				},
			},
			videoSrc: {
				type: String,
				default() {
					return ''
				},
			},
			videoStreamSrc: {
				type: String,
				default() {
					return ''
				},
			},
			videoPreviewAsync: {
				type: Boolean,
				default() {
					return true
				},
			},
			videoPreviewQuality: {
				type: Boolean,
				default() {
					return true
				},
			},
			playIcon: {
				type: String,
				default() {
					return 'https://assets.stanwith.me/graphics/video-play.svg'
				},
			},
			primaryColor: {
				type: String,
				default: '#5383ff',
			},
			fcpComplete: {
				type: Boolean,
				default: false,
			},
			avoidDefaultSpacing: {
				type: Boolean,
				default: false,
			},
			showMask: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				isVideoLoaded: false,
				isPlayStarted: false,
				backgroundImage: emptyImage,
				isVideoPreviewImageReady: false,
				checkVideoPreviewInterval: null,
				checkingVideoPreview: false,
				preloadImageStart: false,
				isPlaying: false,
				isSupportedType: true,
			}
		},
		computed: {
			imagePreview() {
				return this.isVideoPreviewImageReady ? this.backgroundImage : emptyImage
			},
			isVideoReady() {
				return this.fcpComplete
			},
			videoPosterStyle() {
				return `background-image:url(${this.imagePreview}); background-size: contain; background-color: black; background-repeat: no-repeat;`
			},
		},
		async mounted() {
			this.preloadBackgroundImage()
			if (this.fcpComplete) {
				this.initVideo()
			}
		},
		methods: {
			async checkVideoPreviewImage(src) {
				if (!this.checkingVideoPreview) {
					this.checkingVideoPreview = true
					this.isVideoPreviewImageReady = await checkImage(src)
					this.checkingVideoPreview = false
				}
				if (this.isVideoPreviewImageReady) {
					clearInterval(this.checkVideoPreviewInterval)
					this.checkVideoPreviewInterval = null
				}
			},

			onPlay() {
				this.isPlaying = true
			},
			onPause() {
				this.isPlaying = false
			},
			startPlaying() {
				const video = document.getElementById(this.videoId)
				!this.isPlayStarted &&
					video.play().catch(err => {
						this.isSupportedType = false
						console.log(err)
					})
			},
			async preloadBackgroundImage() {
				this.preloadImageStart = true
				const image = `${this.videoSrc}/preview.jpg`
				this.isVideoPreviewImageReady = await checkImage(image)
				if (this.isVideoPreviewImageReady) {
					if (!isImageProcessing(this.videoSrc)) {
						this.backgroundImage = image
						return
					}
					const optimizeImageOptions = {
						format: 'webp',
						quality: 100,
						width: 1920,
					}
					const optimizeImageSrc = await getOptimizeImageUrl(image, { ...optimizeImageOptions })
					preloadImage(optimizeImageSrc)
						.then(() => {
							this.backgroundImage = optimizeImageSrc
						})
						.catch(err => {
							this.backgroundImage = image
						})
				} else {
					if (this.checkVideoPreviewInterval === null) {
						this.checkVideoPreviewInterval = setInterval(() => {
							this.checkVideoPreviewImage(image)
						}, 3000)
					}
				}
				this.preloadImageStart = false
			},
			initVideo() {
				const videoWrapper = document.getElementById('video-wrapper')

				this.$nextTick(() => {
					const videoPlayer = document.getElementById(this.videoId)
					if (this.videoStreamSrc && Hls.isSupported()) {
						var hls = new Hls()
						hls.loadSource(this.videoStreamSrc)
						hls.attachMedia(videoPlayer)
						hls.on(Hls.Events.ERROR, function (event, data) {
							if (data.fatal) {
								// if HLS encounters a fatal error, use the original copy instead
								videoPlayer.src = `${this.videoSrc}#t=0.1`
							}
						})
					} else if (this.videoStreamSrc && videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
						videoPlayer.src = `${this.videoStreamSrc}#t=0.1`
					} else {
						videoPlayer.src = `${this.videoSrc}#t=0.1`
						setTimeout(() => {
							videoPlayer.load()
							videoPlayer.setAttribute('preload', 'auto')
						}, 1000)
					}

					videoPlayer.addEventListener('loadeddata', () => {
						this.isVideoLoaded = true
					})

					videoPlayer.addEventListener('play', () => {
						const playVideoWrapper = document.getElementById('video-wrapper')
						const artplayerApp = document.getElementById('artplayer-app')
						let playFullWidth
						let playFullHeight
						let heightOffset = 0
						if (this.avoidDefaultSpacing) {
							playFullWidth = playVideoWrapper.offsetWidth
							playFullHeight = playVideoWrapper.offsetHeight
						} else {
							playFullWidth = playVideoWrapper.offsetWidth - 46
							playFullHeight = playVideoWrapper.offsetHeight - 48
							heightOffset = 48
						}
						videoPlayer.style.width = `${playFullWidth}px`
						videoPlayer.style.height = `${playFullHeight}px`
						videoWrapper.style.height = `${playFullHeight + heightOffset}px`
						artplayerApp.style.width = `${playFullWidth}px`
						artplayerApp.style.height = `${playFullHeight + heightOffset}px`
						this.isPlayStarted = true
					})

					videoPlayer.addEventListener('pause', () => {})

					videoPlayer.addEventListener('timeupdate', () => {
						this.$emit('timeupdate', videoPlayer.duration, videoPlayer.currentTime)
					})
					videoPlayer.addEventListener('contextmenu', e => e.preventDefault())
				})
			},
			resetLoadVideo() {
				this.isVideoLoaded = false
				this.isPlayStarted = false
				this.backgroundImage = emptyImage
				this.isVideoPreviewImageReady = false
				this.checkVideoPreviewInterval = null
				this.checkingVideoPreview = false
				this.preloadImageStart = false
				this.preloadBackgroundImage()

				if (this.fcpComplete) {
					this.initVideo()
				}
			},
		},
		watch: {
			fcpComplete(isReady) {
				if (isReady) {
					this.initVideo()
				}
			},
			videoSrc() {
				this.resetLoadVideo()
			},
			isVideoPreviewImageReady(val) {
				if (val) {
					clearInterval(this.checkVideoPreviewInterval)
					this.checkVideoPreviewInterval = null
				}
				if (!this.preloadImageStart) {
					this.preloadBackgroundImage()
				}
			},
		},
	}
</script>

<style lang="scss">
	.video-wrapper {
		padding: 23px 23px;
		width: 100%;

		video {
			position: relative;
			width: 100%;
			border-radius: 20px;
			justify-content: center;
			align-items: center;
			padding: 0 0;
		}
	}

	.no-radius {
		video {
			border-radius: 0px !important;
		}

		.art-poster {
			border-radius: 0px !important;
		}
	}

	.artplayer-app {
		background-color: transparent !important;
		border: none;
		width: 414px;
		height: 248px;

		&.art-auto-size {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 100%;
			aspect-ratio: 1.65;
			height: auto;
		}

		.art-video-player {
			width: 100%;
			height: 99.9636%;
			background-color: transparent !important;
			zoom: 1;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			-ms-high-contrast-adjust: none;
			color: #eee;
			direction: ltr;
			display: flex;
			font-family: Roboto, Arial, Helvetica, sans-serif;
			font-size: 14px;
			line-height: 1.3;
			margin: 0 auto;
			outline: 0;
			position: relative;
			text-align: left;
			touch-action: manipulation;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			z-index: 12;
		}

		.art-video-player {
			* {
				box-sizing: border-box;
				margin: 0;
				padding: 0;
			}

			.art-video {
				bottom: 0;
				height: 100% !important;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 100% !important;
				cursor: pointer;
				z-index: 10;
			}

			.art-poster {
				bottom: 0;
				height: 100%;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				background-position: 50%;
				background-repeat: no-repeat;
				background-size: cover;
				pointer-events: none;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				z-index: 11;
				border-radius: 20px;
			}

			.art-mask {
				display: flex;
				align-items: center;
				bottom: 0;
				height: 100%;
				justify-content: center;
				left: 0;
				overflow: hidden;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				z-index: 13;

				.art-state {
					display: flex;
					width: 98px;
					height: 98px;
					border-radius: 100%;
					align-items: center;
					justify-content: center;
					position: absolute;
					opacity: 1 !important;
					right: auto !important;
					bottom: auto !important;
					background: #9f9f9f9e;
					animation: slideup 1.2s ease-in-out;

					svg {
						transform: translateX(3px);
					}
				}
			}

			.art-icon {
				align-items: center;
				display: inline-flex;
				justify-content: center;
				line-height: 1.5;

				svg {
					fill: #fff;
				}
			}
		}
	}

	@media (max-width: 420px) {
		.art-auto-size {
			.art-state {
				width: 89px !important;
				height: 89px !important;
				background-size: 22px;
				background-position: calc(50% + 3px) calc(50% - 0px);
			}
		}
	}

	@media (max-width: 380px) {
		.art-auto-size {
			.art-state {
				width: 79px !important;
				height: 79px !important;
				background-size: 20px;
				background-position: calc(50% + 3px) calc(50% - 0px);
			}
		}
	}

	@media (max-width: 360px) {
		.art-auto-size {
			.art-state {
				width: 79px !important;
				height: 79px !important;
				background-size: 18px;
				background-position: calc(50% + 3px) calc(50% - 0px);

				img {
					width: 30px !important;
					height: 30px !important;
				}
			}
		}
	}

	@media (max-width: 320px) {
		.art-auto-size {
			.art-state {
				width: 65px !important;
				height: 65px !important;
				background-size: 18px;
				background-position: calc(50% + 3px) calc(50% - 0px);

				img {
					width: 30px !important;
					height: 30px !important;
				}
			}
		}
	}

	@media (max-width: 280px) {
		.art-auto-size {
			.art-state {
				width: 55px !important;
				height: 55px !important;
				background-size: 14px;
				background-position: calc(50% + 3px) calc(50% - 0px);

				img {
					width: 25px !important;
					height: 25px !important;
				}
			}
		}
	}
	.video-issue {
		text-align: center;
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background: var(--stan-gray-light-color);
		border-radius: 12px;
		margin: 30px !important;
		gap: 10px;
		padding: 20px !important;
		box-shadow: 0px 15px 50px 10px rgba(0, 16, 109, 0.1), 0px -5px 10px 0px rgba(0, 0, 0, 0.01) inset;
		.header {
			color: var(--stan-text-dark-color);
			font-size: 18px;
			font-weight: 500;
		}
		.subheader {
			color: var(--stan-text-light-color);
			font-size: 14px;
			font-weight: 400;
		}
	}
</style>

import { deepMerge } from './utils'

import english from '../locales/en.json'
import french from '../locales/fr.json'
import spanish from '../locales/es.json'
import german from '../locales/de.json'

const locales = {
	en: english,
	fr: french,
	es: spanish,
	de: german,
}

export default {
	mounted() {
		const currentLocale = this.$i18n.locale

		let localeUse = 'en'
		if (Object.keys(locales).includes(currentLocale)) {
			localeUse = currentLocale
		}

		const combinedLocales = deepMerge(this.$i18n.getLocaleMessage(currentLocale), locales[localeUse])
		this.$i18n.setLocaleMessage(currentLocale, combinedLocales)
	},
}

<template>
	<div class="card-payment" :class="{ 'card-payment-error': cardPaymentError, submitting: isFormSubmitting }">
		<div class="transaction-form card-payment-bg" :class="{ 'border rounded-lg pl-3': card }">
			<template v-if="displayMode === 'live'">
				<div v-show="stripeReady" class="form-bar my-0">
					<div class="form-border-radius no-gutters my-2">
						<div class="payment-element-wrapper" id="payment-element"></div>
						<div class="payment-element-wrapper" id="address-element"></div>
					</div>
				</div>
				<div v-show="!stripeReady" style="height: 120px">
					<div class="loader-wrapper h-100">
						<div class="loader-block">
							<HollowDotsSpinner
								class="d-flex align-items-center"
								:animation-duration="1000"
								:dot-size="14"
								:dots-num="3"
								:color="primaryColor"
							/>
						</div>
					</div>
				</div>
			</template>
			<template v-if="displayMode === 'preview'">
				<div class="form-bar my-0">
					<div class="no-gutters my-2">
						<div class="payment-element-wrapper" id="payment-element"></div>
						<div class="payment-element-wrapper" id="address-element"></div>
					</div></div
			></template>
		</div>
		<div v-html="resultErrorMessage" class="error-msg"></div>
	</div>
</template>

<script>
	import HollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'
	import { getThemeColors } from '~/stan-vue-shared/components/utils.js'
	import Il8nMixin from './Il8nMixin'

	const loadStripe = async () => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = '//js.stripe.com/v3/'
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}
	export default {
		name: 'StripePaymentElement',
		mixins: [Il8nMixin],
		components: {
			HollowDotsSpinner,
		},
		props: {
			displayMode: { type: String, default: 'live' }, // 'live', 'preview'
			previewCurrency: { type: String, default: 'usd' },
			previewAmount: { type: Number, default: 999 },
			isPaid: { type: Boolean, default: false },
			pageId: { type: Number },
			store: { type: Object, default: () => {} },
			fcpComplete: { type: Boolean, default: false },
			isFormSubmitting: { type: Boolean },
			resellRightsUuid: { type: String },
			affiliateCode: { type: String },
			theme: { type: String },
			product: { type: Object, default: () => {} },
			currency: { type: String },
			triggerSubmit: {
				type: Function,
			},
			analyticsProps: {
				type: Object,
				default: () => {},
			},
			fanName: { type: String, default: '' },
			fanEmail: { type: String, default: '' },
			showWallet: { type: Boolean, default: true },
			stripeProcessingAccount: { type: String },
			stripeProcessingAccountType: { type: String },
			username: { type: String, default: undefined },
			isSubscriptionWithAlternateOptionSelected: { type: Boolean, default: false },
			price_key: { type: String, default: undefined },
		},
		data() {
			return {
				name: 'stripe',
				stripeReady: false,
				cardError: null,
				card: null,
				brand: null,
				stripe: null,
				paymentData: null,
				paymentMethodType: null,
				resultErrorMessage: '',
				elements: null,
				paymentRequestElement: null,
				payingWithWallet: false,
				paymentRequest: null,
			}
		},
		emits: ['paymentData', 'getCard', 'paymentSuccess', 'paymentError', 'paymentIntentId', 'gatewayAvailable'],
		computed: {
			cardPaymentError() {
				return this.resultErrorMessage !== '' || this.cardError
			},
			primaryColor() {
				return this.store?.data?.primary_color || '#5383ff'
			},
			secondaryColor() {
				return this.store?.data?.secondary_color
			},
			walletSetting() {
				return this.showWallet ? 'auto' : 'never'
			},
			computedUsername() {
				return this.username || this.$route.params.username
			},
		},
		async mounted() {
			if (this.displayMode === 'preview') {
				await this.initStripe()
			}
		},
		methods: {
			async initStripe() {
				await loadStripe()
				if (this.displayMode === 'live') {
					if (this.isPaid) {
						await loadCaptcha(this.$config.captcha_site_key)
						grecaptcha.enterprise.ready(() => {
							this.createPaymentData(this.pageId, true)
						})
					}
				} else {
					this.createPreviewCardField()
				}
			},
			async createPaymentData(pageId = this.page_id, initializeCardField = false) {
				const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
					action: 'CreatePaymentIntent',
				})
				const analyticsData = await this.$getAnalyticsData({
					meta: { username: this.computedUsername },
					props: this.analyticsProps,
				})

				const payload = {
					page_id: pageId ? pageId : this.pageId,
					resell_rights_uuid: this.resellRightsUuid,
					affiliate_code: this.affiliateCode,
					affiliated_username: this.$route.query?.su || sessionStorage.getItem('LastVisitStore'),
					token: token,
					analytics_meta: analyticsData.meta,
					analytics_props: analyticsData.props,
					mode: 'stripe-payment-element',
					stripe_processing_account_type: this.stripeProcessingAccountType,
					price_key: this.price_key,
				}
				this.$axios
					.post('v1/integrations/stripe/create-payment-intent', payload, { baseURL: process.env.NUXT_ENV_PYTHON_API })
					.then(async response => {
						this.paymentData = response.data
						const self = this
						this.$emit('paymentData', { name: 'stripe', data: this.paymentData })
						setTimeout(() => {
							self.$emit('gatewayAvailable', {
								name: 'stripe',
								available: true,
								isConnect: self.paymentData.transaction_origin === 'user',
							})
						}, 20)
						if (initializeCardField) {
							this.createCardField(this.paymentData)
						}
					})
					.catch(error => {
						if (error.request.status == 401) {
							this.$notify({
								group: '1',
								title: this.$t('shared.payment.oops_error'),
								text: this.$t('shared.payment.browser_error_text'),
								type: 'error',
							})
						} else {
							this.$notify({
								group: '1',
								title: this.$t('shared.payment.payment_not_setup_title', {
									username: this.computedUsername,
								}),
								text: this.$t('shared.payment.payment_not_setup_text'),
								type: 'error',
							})
							this.$sentry.captureException(error)
						}
						this.paymentData = null
						console.error(error)
						this.$emit('paymentData', { name: 'stripe', data: null })
					})
			},
			createPreviewCardField() {
				// show a preview payment element as a placeholder for UI purposes
				const appearance = this.getPaymentElementAppearance()
				this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY || process.env.NUXT_ENV_STRIPE_PK, {})

				this.elements = this.stripe.elements({
					mode: 'payment',
					currency: 'usd',
					amount: 1099,
					loader: 'auto',
					appearance,
					locale: this.$i18n.locale || 'en',
				})
				const paymentElement = this.elements.create('payment', {
					wallets: {
						applePay: this.walletSetting,
						googlePay: this.walletSetting,
					},
					defaultValues: {
						billingDetails: {
							name: this.fanName,
							email: this.fanEmail,
						},
					},
					fields: {
						billingDetails: {
							name: 'never',
							email: 'never',
						},
					},
				})
				if (document.getElementById('payment-element')) paymentElement.mount('#payment-element')
			},
			createCardField(pi) {
				const appearance = this.getPaymentElementAppearance()

				const options = pi.transaction_origin === 'user' && pi.charge_type === 'direct' ? { stripeAccount: pi.account_id } : {}

				const stripe_key = this.stripeProcessingAccount || process.env.NUXT_ENV_STRIPE_PK
				this.stripe = Stripe(stripe_key, options)

				this.elements = this.stripe.elements({
					clientSecret: pi.secret,
					loader: 'auto',
					appearance,
					locale: this.$i18n.locale || 'en',
				})

				const paymentElement = this.elements.create('payment', {
					wallets: {
						applePay: this.walletSetting,
						googlePay: this.walletSetting,
					},
					defaultValues: {
						billingDetails: {
							name: this.fanName,
							email: this.fanEmail,
						},
					},
					fields: {
						billingDetails: {
							name: 'never',
							email: 'never',
						},
					},
				})
				if (document.getElementById('payment-element')) paymentElement.mount('#payment-element')

				paymentElement.on('change', async event => {
					if (event.elementType === 'payment') {
						await this.elements.fetchUpdates()
					}
					// Payment type is used to re-calculate the application fees
					if (event.value?.type !== this.paymentMethodType) {
						this.paymentMethodType = event.value?.type
					}
					if (event.elementType === 'payment' && event.value?.type === 'afterpay_clearpay') {
						var addressElement = this.elements.create('address', {
							mode: 'shipping',
							fields: { phone: 'never', name: 'never' },
							defaultValues: {
								name: this.fanName,
							},
						})
						if (document.getElementById('address-element')) addressElement.mount('#address-element')
					} else {
						var addressElement = this.elements.getElement('address')
						addressElement?.destroy()
					}

					if (event.error) {
						this.cardError = event.error.message
					} else {
						this.cardError = null
					}
				})

				this.card = paymentElement
				this.$emit('getCard', paymentElement, this.stripe)
				this.stripeReady = true
			},
			async processPayment(secret, card, stripe) {
				if (this.payingWithWallet) return
				this.$emit('paymentInit')
				this.resultErrorMessage = ''
				this.cardError = null
				if (!stripe) {
					stripe = this.stripe
				}
				if (!card) {
					card = this.card
				}
				if (!secret) {
					secret = this.paymentData?.secret
				}
				// Trigger form validation and wallet collection
				const { error: submitError } = await this.elements.submit()
				if (submitError) {
					this.cardError = submitError.message
					this.$emit('paymentError', { message: submitError.message })
					return
				}
				// Get front-end url
				let frontendURI = process.env.NUXT_ENV_FRONT_URL.replace('www.', '')
				stripe
					.confirmPayment({
						clientSecret: secret,
						elements: this.elements,
						redirect: 'if_required',
						confirmParams: {
							return_url: `${frontendURI}${this.computedUsername}/success?failure_url=${window.location.href}`,
							payment_method_data: {
								billing_details: {
									name: this.fanName,
									email: this.fanEmail,
								},
							},
						},
					})
					.then(result => {
						if (result.error) {
							this.resultErrorMessage = result.error.message
							this.$emit('paymentError', { message: result.error.message })
							return
						}
						this.$emit('paymentSuccess')
					})
					.catch(error => {
						console.log(error)
						this.$emit('paymentError', { message: error.message })
						this.$sentry.captureException(
							new Error('Could not process payment using Stripe', { extra: { error, paymentData: this.paymentData } })
						)
					})
			},
			updatePaymentData(payload) {
				const payload_use = {
					mode: 'stripe-payment-element',
					payment_method_type: this.paymentMethodType,
					...payload,
				}
				return this.$axios
					.patch('v1/integrations/stripe/payment-intent', payload_use, { baseURL: process.env.NUXT_ENV_PYTHON_API })
					.then(async response => {
						if (!this.paymentData || response.data.id !== this.paymentData.id) {
							this.paymentData = response.data
						}
						await this.elements.fetchUpdates()
						this.$emit('paymentData', { name: 'stripe', data: this.paymentData })
						return response.data
					})
					.catch(error => {
						this.$notify({
							group: '1',
							title: this.$t('shared.payment.error_title'),
							text: this.$t('shared.payment.error_text'),
							type: 'error',
						})
						this.$emit('paymentData', { name: 'stripe', data: null })
						this.$sentry.captureException(new Error('Could not update payment-intent', { extra: { error, payload } }))
						throw error
					})
			},
			getPaymentElementAppearance() {
				const themeColors = getThemeColors(this.theme, this.primaryColor, this.secondaryColor)

				const appearance = {
					theme: 'stripe',
					variables: {
						fontSizeBase: '14px',
						focusBoxShadow: 'transparent',
						colorPrimary: this.primaryColor,
						colorBackground: themeColors.secondaryColor,
						colorText: themeColors.primaryTextColor,
						colorTextSecondary: themeColors.secondaryTextColor,
						colorTextPlaceholder: themeColors.secondaryTextColor,
					},
				}
				switch (this.theme) {
					case 'kels':
						appearance.variables.theme = 'none'
						appearance.variables.borderRadius = '0px'
						break
					case 'moderno':
					case 'tyla':
						appearance.variables.borderRadius = '0px'
						break
					case 'stone':
						appearance.variables.borderRadius = '0px'
						break
					case 'nightview':
						appearance.variables.borderRadius = '10px'
						break
					case 'eclipse':
						appearance.variables.borderRadius = '10px'
						appearance.variables.colorPrimary = '#ffffff'
						appearance.variables.colorText = '#000000'
						appearance.variables.colorTextPlaceholder = '#999'
						appearance.variables.colorBackground = '#ffffff'
						appearance.variables.colorTextSecondary = '#999'
						appearance.variables.colorIconTab = this.primaryColor
						appearance.variables.colorIconTabSelected = this.primaryColor
						appearance.rules = {
							'.Label': {
								color: '#ffffff',
							},
							'.TabLabel': {
								color: '#999',
							},
							'.TabLabel--selected': {
								color: themeColors.primaryTextColor,
							},
						}
						break
					default:
						break
				}
				if (!appearance.rules) appearance.rules = {}
				appearance.rules['.RedirectText'] = {
					fontSize: '0px',
				}

				appearance.rules['.Tab--selected'] = {
					color: this.primaryColor,
					borderColor: themeColors.secondaryColor,
				}
				appearance.rules['.Tab--selected'] = {
					color: themeColors.primaryColor,
					borderColor: themeColors.secondaryColor,
					boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px ${themeColors.primaryColor}`,
				}

				appearance.rules['.Input'] = {
					padding: '16px',
					lineHeight: '18px',
				}

				return appearance
			},
		},
		watch: {
			fcpComplete(isReady) {
				if (isReady) {
					this.$nextTick(async () => {
						await this.initStripe()
					})
				}
			},
			fanName: {
				immediate: true,
				handler(newVal) {
					this.elements?.getElement('payment')?.update({
						defaultValues: {
							billingDetails: {
								name: newVal,
							},
						},
					})
				},
			},
			fanEmail: {
				immediate: true,
				handler(newVal) {
					this.elements?.getElement('payment')?.update({
						defaultValues: {
							billingDetails: {
								email: newVal,
							},
						},
					})
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.payment-element-wrapper {
		overflow: unset;
		margin: 10px 0;
	}

	#payment-element::v-deep {
		margin: -4px;
		& > div {
			width: calc(100% - 2px);
			iframe {
				margin: 1px !important;
				width: 100% !important;
			}
		}
	}

	@supports (-webkit-appearance: -apple-pay-button) {
		.apple-pay-button {
			display: inline-block;
			-webkit-appearance: -apple-pay-button;
			width: 100%;
			height: 45px;
			:hover {
				cursor: pointer;
				filter: brightness(1.2);
			}
		}
		.apple-pay-button-black {
			-apple-pay-button-style: black;
		}
		.apple-pay-button-white {
			-apple-pay-button-style: white;
		}
		.apple-pay-button-white-with-line {
			-apple-pay-button-style: white-outline;
		}
	}

	@supports not (-webkit-appearance: -apple-pay-button) {
		.apple-pay-button {
			display: inline-block;
			background-size: 100% 60%;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			border-radius: 5px;
			padding: 0px;
			box-sizing: border-box;
			min-width: 200px;
			min-height: 32px;
			max-height: 64px;
			width: 100%;
		}
		.apple-pay-button-black {
			background-image: -webkit-named-image(apple-pay-logo-white);
			background-color: black;
		}
		.apple-pay-button-white {
			background-image: -webkit-named-image(apple-pay-logo-black);
			background-color: white;
		}
		.apple-pay-button-white-with-line {
			background-image: -webkit-named-image(apple-pay-logo-black);
			background-color: white;
			border: 0.5px solid black;
		}
	}
	#payment-request-element {
		height: 45px;
		background: #2c2e2f;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 25px;
		}
		&:hover {
			cursor: pointer;
			filter: brightness(1.2);
		}
	}
</style>
<style>
	.grecaptcha-badge {
		display: none !important;
	}
</style>
